<template>
  <div class="home">
    <Loader/>
    <video playsinline autoplay muted loop id="bg1">
      <source src="../assets/bg1.mp4" type="video/mp4">
      Your browser does not support HTML5 video.
    </video>
    <div class="voile"></div>

    <div class="container">
      <div class="text-box">
        <p>Bienvenue dans</p>
        <h1>l'Agence-Brome</h1>
        <h3>Vos idées, notre savoir-faire, une agence web.</h3>
        <h4>Création de site internet | Webdesign | Responsive design | E-Commerce | Référencement</h4>
        <div class="row">
          <router-link to="/about">En savoir plus</router-link>
          <router-link to="/nosClients">Ils nous ont fait confiance</router-link>
          <span>Besoin d'un devis ? Vous voulez en savoir plus ?<br>N'hésitez plus, et contactez nous pour toute demande d'informations supplémentaires.</span>
        </div>
        <!--<p class="lienOrdibessin">Filiale d'<a href="https://ordibessin.fr/"><img class="logoOrdibessin" src="../assets/logoOrdibessin.png" alt=""><span class="OB1"><span class="OB2">Ordi</span>Bessin.fr</span></a></p>-->
      </div>
      <div class="social-icons">
        <a target="blank" href="https://www.instagram.com/agence.brome">
          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" class="instagram svg-inline--fa fa-instagram fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <defs>
              <linearGradient id="gradient" gradientUnits="userSpaceOnUse" gradientTransform="rotate(-45) translate(-320)">
                <stop stop-color="#fff" offset="0" />
                <stop stop-color="#fff" offset="0.05" />
                <stop stop-color="#fff" offset="0.45" />
                <stop stop-color="#fff" offset="0.6" />
                <stop stop-color="#fff" offset="0.9" />
              </linearGradient >
              <mask id="clock-icon-mask">
                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" fill="white" />
              </mask>
            </defs>
            <g mask="url(#clock-icon-mask)">
              <rect x="0" y="0" width="512" height="512" fill="url(#gradient)" />
            </g>
          </svg>
        </a>

        <a target="blank" href="https://www.facebook.com/agence.brome/"><i class="fab fa-facebook"></i></a>
        <a target="blank" href="https://www.linkedin.com/company/agence-brome"><i class="fab fa-linkedin"></i></a>
      </div>
    </div>
  </div>
</template>

<style scoped>
#bg1 {
  position: fixed;
  right: 0;
  top: -50px;
  min-width: 100%;
  min-height: 100%;
  z-index: -1000;
}

.voile{
  position: absolute;
  background-color: rgba(162, 0, 255, 0);
  width: 100%;
  height: 100vh;
}

.container{
  width: 80%;
  /*height: 100vh;*/
  padding: 0 10%;
  color: #ffffff;
}

.text-box{
  position: absolute;
  bottom: 15%;
}

.text-box p{
  font-size: 35px;
  font-weight: 600;
}

.text-box h1{
  font-size: 90px;
  line-height: 120px;
  margin-left: -6px;
  color: transparent;
  -webkit-text-stroke: 1px #ffffff;
  background-image: url("../assets/back.png");
  background-clip: text;
  -webkit-background-clip: text;
  background-position: 0 0;
  animation: back 20s linear infinite;
}

.text-box h3{
  font-size: 20px;
  margin-bottom: 20px;
}

.text-box .row{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.text-box .row a{
  color: #ffffff;
  text-decoration: none;
  padding: 10px 20px;
  margin-right: 20px;
  border: 2px solid #ffffff;
  font-weight: lighter;
  transition: 0.5s;
}

.text-box .row a:hover{
  box-shadow: inset 0 0 0 2em #ffffff;
  color: #000000;
}

.lienOrdibessin{
  margin: 50px 0 0 100px;
  font-size: 25px!important;
}

@media screen and (max-width: 999px) {
  .lienOrdibessin{
    margin: 50px 0 0 0;
  }
}

.lienOrdibessin a{
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.logoOrdibessin{
  width: 50px;
  vertical-align:middle;
}

.OB1{
  font-family: 'xenotronnormal';
}

.OB2{
  color: orange;
}

.social-icons{
  position: absolute;
  right: 5%;
  bottom: 15%;
}

.social-icons a{
  text-decoration: none;
  color: #ffffff;
  font-size: 2rem;
  display: block;
  margin: 30px 0;
  transition: 0.3s;
}

.social-icons a svg{
  transition: 0.3s;
}

.fa-facebook:hover{
  color: #4267B2;
}

.fa-linkedin:hover{
  color: #0a66c2;
}

.instagram stop:nth-child(1), .instagram stop:nth-child(2), .instagram stop:nth-child(3), .instagram stop:nth-child(4), .instagram stop:nth-child(5) {
    stop-color: #fff;
    transition: 0.3s;
}

.instagram:hover stop:nth-child(1) {
    stop-color: #fdf497;
}

.instagram:hover stop:nth-child(2) {
    stop-color: #fdf497;
}

.instagram:hover stop:nth-child(3) {
    stop-color: #fd5949;
}

.instagram:hover stop:nth-child(4) {
    stop-color: #d6249f;
}

.instagram:hover stop:nth-child(5) {
    stop-color: #285AEB;
}

@media screen and (max-width: 999px) {
  .text-box{
    top: 15%;
  }

  .text-box h1{
    font-size: 70px;
  }

  .text-box .row a{
  margin-bottom: 30px;
  }

  .nav-resp {
    display:block;
  }
}

@media screen and (max-width: 500px) {
  .text-box{
    position: initial;
    margin: 100px 0 90px 0;
  }

  .social-icons{
    position: fixed;
    right: 0;
    left: 0;
    bottom: 28px;
    width: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .social-icons a{
    display: inline-block;
    margin: 10px;
  }

}

@keyframes back{
  100%{
    background-position: 2000px 0;
  }
}
</style>

<script>
import Loader from '@/components/Loader.vue'

export default {
  components: {
    Loader
  },
  mounted () {
    document.querySelector('.navCheckbox').checked = false
    setTimeout(
      function () {
        document.querySelector('#loader').style.display = 'none'
      }, 2300)
    window.scrollTo(0, 0)
  },
  methods: {
  }
}
</script>
