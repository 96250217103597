<template>
  <div id="loader">
    <div class="case">
      <div class="loader">
        <div class="logoLoad">
          <span class="sq1"></span>
          <span class="sq2"></span>
          <span class="sq3"></span>
          <span class="sq4"></span>
          <p>Br</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#loader {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  z-index: 1000;
  animation: opacity 1s 1.3s forwards;
}

#loader .logoLoad{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 200px;
  height: 200px;
}

#loader .logoLoad p{
  position: absolute;
  font-size: 7em;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  animation: apparition 1s forwards;
}

.sq {
  position: absolute;
  width: 120px;
  height: 120px;
  border: rgb(229, 101, 255) solid 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  cursor: pointer;
}

.sq1 {
  position: absolute;
  width: 140px;
  height: 10px;
  transform: scaleX(0);
  background-color: rgb(154, 0, 185);
  top:15%;
  left: 15%;
  cursor: pointer;
  animation: sq1 0.2s 0.5s forwards;
  transform-origin: left 50%;
}

.sq2 {
  position: absolute;
  width: 10px;
  height: 140px;
  transform: scaleX(0);
  background-color: rgb(154, 0, 185);
  top:15%;
  left: 80%;
  cursor: pointer;
  animation: sq2 0.2s 0.7s forwards;
  transform-origin: 50% top;
}

.sq3 {
  position: absolute;
  width: 140px;
  height: 10px;
  transform: scaleX(0);
  background-color: rgb(154, 0, 185);
  top:80%;
  left: 15%;
  cursor: pointer;
  animation: sq3 0.2s 0.9s forwards;
  transform-origin: right 50%;
}

.sq4 {
  position: absolute;
  width: 10px;
  height: 140px;
  transform: scaleX(0);
  background-color: rgb(154, 0, 185);
  top:15%;
  left: 15%;
  cursor: pointer;
  animation: sq4 0.2s 1.1s forwards;
  transform-origin: 50% bottom;
}

@keyframes sq1 {
  0% { transform: scaleX(0);}
  100% { transform: scaleX(1);}
}

@keyframes sq2 {
  0% { transform: scaleY(0);}
  100% { transform: scaleY(1);}
}

@keyframes sq3 {
  0% { transform: scaleX(0);}
  100% { transform: scaleX(1);}
}

@keyframes sq4 {
  0% { transform: scaleY(0);}
  100% { transform: scaleY(1);}
}

@keyframes apparition {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>
