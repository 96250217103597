<template>
  <div id="app">

    <div class="nav-resp">
      <input class="navCheckbox" type="checkbox" />
      <span class="span1"></span>
      <span class="span2"></span>
      <span class="span3"></span>

      <ul>
        <li><router-link to="/">Accueil</router-link></li>
        <li><router-link to="/about">A propos</router-link></li>
        <li><router-link to="/prestations">Nos prestations</router-link></li>
        <li><router-link to="/nosClients">Nos clients</router-link></li>
        <li><router-link to="/contact">Nous contacter</router-link></li>
      </ul>

    </div>
    <!--<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>-->
    <nav id="nav">
      <span class="square1"></span>
      <span class="square2"></span>
      <span class="square3"></span>
      <span class="square4"></span>
      <p class="logo">Brome</p>
      <ul>
        <li><router-link to="/">Accueil</router-link></li>
        <li><router-link to="/about">A propos</router-link></li>
        <li><router-link to="/prestations">Nos prestations</router-link></li>
        <li><router-link to="/nosClients">Nos clients</router-link></li>
        <li><router-link to="/contact">Nous contacter</router-link></li>
      </ul>
    </nav>
      <router-view/>
      <footer><p>&copy; 2022 Agence-Brome.fr - Tout droits réservés</p></footer>
  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

@font-face {
    font-family: 'xenotronnormal';
    src: url('./fonts/xenotron-webfont.woff2') format('woff2'),
         url('./fonts/xenotron-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

*{
  margin: 0;
  padding: 0;
}

body{
  background-color: black;
}

#app {
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav-resp
{
  display: block;
  position: fixed;
  top: 50px;
  right: 50px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  display:none;
}

.nav-resp input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  -webkit-touch-callout: none;
}

.nav-resp span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

.nav-resp input:checked ~ .span1{
  transform: rotate(45deg);
}

.nav-resp input:checked ~ .span2{
  opacity: 0;
}

.nav-resp input:checked ~ .span3{
  transform: rotate(-45deg) translate(-1px, -2px);
}

.nav-resp input:checked ~ ul{
  left: 0%;
}

.nav-resp ul{
  position: fixed;
  width: 300px;
  padding: 50px;
  top: 0%;
  left: -110%;
  transition: .3s;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  list-style-type: none;
}

.nav-resp ul li{
  margin: 50px 0;
}

.nav-resp ul li a{
  color: #ffffff;
  text-decoration: none;
  position: relative;
  text-shadow: rgb(0, 0, 0) 3px 3px 4px;
  font-size: 20px;
}

nav{
  display: flex;
  position: fixed;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  padding: 25px 0;
  z-index: 100;
  color: #ffffff;
  transition: 2s;
}

.sticky {
  background-color: #00000080;
  box-shadow: #00000080 2px 2px 5px;
}

@media screen and (max-width: 999px) {
  nav {
    display:none;
  }

  .nav-resp {
    display:block;
  }
}

.logo{
  width: 80px;
  cursor: pointer;
  font-size: 2.3rem;
  font-weight: bold;
  text-shadow: rgb(0, 0, 0) 3px 3px 4px;
  padding-left: 100px;
  overflow: hidden;
  border-right: .15em solid rgb(255, 255, 255);
  white-space: nowrap;
  letter-spacing: .05em;
  animation:
    typing 4s 1.4s steps(8, end) forwards,
    blink-caret .5s step-end infinite,
    cursorStop 1s 6s forwards;
}

.square {
  position: absolute;
  width: 50px;
  height: 50px;
  border: rgb(154, 0, 185) solid 4px;
  left: 90px;
  cursor: pointer;
}
.square1 {
  position: absolute;
  width: 58px;
  height: 4px;
  transform: scaleX(0);
  background-color: rgb(154, 0, 185);
  top:18px;
  left: 90px;
  cursor: pointer;
  animation: square1 0.2s 6s forwards;
  transform-origin: left 50%;
}

.square2 {
  position: absolute;
  width: 4px;
  height: 58px;
  transform: scaleX(0);
  background-color: rgb(154, 0, 185);
  top:18px;
  left: 144px;
  cursor: pointer;
  animation: square2 0.2s 6.2s forwards;
  transform-origin: 50% top;
}

.square3 {
  position: absolute;
  width: 58px;
  height: 4px;
  transform: scaleX(0);
  background-color: rgb(154, 0, 185);
  top:72px;
  left: 90px;
  cursor: pointer;
  animation: square3 0.2s 6.4s forwards;
  transform-origin: right 50%;
}

.square4 {
  position: absolute;
  width: 4px;
  height: 58px;
  transform: scaleX(0);
  background-color: rgb(154, 0, 185);
  top:18px;
  left: 90px;
  cursor: pointer;
  animation: square4 0.2s 6.6s forwards;
  transform-origin: 50% bottom;
}

nav ul{
  flex: 1;
  text-align: right;
  padding-right: 100px;
}

nav ul li{
  display: inline-block;
  list-style-type: none;
  margin: 10px 30px;
}

nav ul li a{
  color: #ffffff;
  text-decoration: none;
  position: relative;
  text-shadow: rgb(0, 0, 0) 3px 3px 4px;
}

nav ul li a::after{
  content: '';
  width: 0%;
  height: 2px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  transition: 0.3s;
}

nav ul li a:hover::after{
  width: 90%;
}

nav ul li a.router-link-exact-active::after{
  width: 90%;
}

footer{
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 5px;
}

footer p{
  color: #ffffff;
}

@keyframes typing {
  0% { width: 0 }
  30% { width: 130px }
  70% { width: 130px }
  100% { width: 42px }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: rgb(255, 255, 255) }
}

@keyframes cursorStop {
  0% { border-right: .15em solid rgb(255, 255, 255); }
  100% { border-right: .15em solid rgba(255, 255, 255, 0); }
}

@keyframes square1 {
  0% { transform: scaleX(0);}
  100% { transform: scaleX(1);}
}

@keyframes square2 {
  0% { transform: scaleY(0);}
  100% { transform: scaleY(1);}
}

@keyframes square3 {
  0% { transform: scaleX(0);}
  100% { transform: scaleX(1);}
}

@keyframes square4 {
  0% { transform: scaleY(0);}
  100% { transform: scaleY(1);}
}

/*#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}*/
</style>

<script>
// @ is an alias to /src
export default {
  name: 'App',
  data () {
    return {
      navbar: null
    }
  },
  mounted () {
    window.addEventListener('scroll', this.stickyBar)
    this.navbar = document.getElementById('nav')
    this.btnScroll = document.getElementById('btnScroll')
  },
  methods: {
    stickyBar (e) {
      if (window.pageYOffset >= 1) {
        this.navbar.classList.add('sticky')
      } else {
        this.navbar.classList.remove('sticky')
      }
    }
  }
}
</script>
